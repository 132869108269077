import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ilmiprints from "../../Assets/Projects/ilmiprints.png";
import relaxlounge from "../../Assets/Projects/relaxlounge.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Most of our work is done as consultants working for companies. 
          <br />But here are a few smaller freelancing projects that we can share:
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={relaxlounge}
              isBlog={false}
              title="Relax Lounge SPA"
              description="Full project including design, staff accounts, order managements and an advanced booking system with payment using Klarna and Google Calendar sync."
              link="https://relaxlounge.se"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ilmiprints}
              isBlog={false}
              title="Ilmi Prints"
              description="Designing a complete eShop for posters. In this project we did everything from the design to the ecommerce system. Setup of the payment gateway and the connection between orders and the fulfillment center."
              link="https://ilmiprints.com"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
