import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            We love to <span className="purple">code </span>
            and <span className="purple"> design</span> and everything in between! 
            We have coded since we were kids back in the 90:s and surfed on the waves of different technologies throughout the years. We are driven by the Scandinavian spirit of clean design and quality, with the user experience in focus. Nothing is too hard and everything is possible!
            <br />
            <br />
            Apart from design and coding we also love:
          </p>
          <ul>
          <li className="about-activity">
              <ImPointRight /> 3D modelling 
            </li>
            <li className="about-activity">
              <ImPointRight /> Smart home solutions
            </li>
            <li className="about-activity">
              <ImPointRight /> Growing and gardening
            </li>
            <li className="about-activity">
              <ImPointRight /> DIY projects and carpentry 
            </li>
            <li className="about-activity">
              <ImPointRight /> Photography and movie making 
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
          “Be a yardstick of quality. Some people aren’t used to an environment where excellence is expected.”{" "}
          </p>
          <footer className="blockquote-footer">Steve Jobs</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
